import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { checkDocumentFileName } from "../../api/api";
import { checkFileExtension } from "../Helpers/Constants";
const AWS = require("aws-sdk");
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});

export default function App({
  handleSetAttachment,
  handleResetAttachment,
  upLoadingStatus,
  updateAttachment,
  showAlert,
}) {
  const [file, setFile] = useState("");
  const [attachLink, setAttachLink] = useState("");
  const [loader, setLoader] = useState(false);
  const [updateFileName, setUpdateFileName] = useState("");

  useEffect(() => {
    if (updateAttachment) {
      if (updateAttachment.file_name) {
        setFile(updateAttachment);
        setUpdateFileName(updateAttachment.file_name);
      }
    }
  }, []);

  useEffect(() => {
    if (updateAttachment) {
      if (updateAttachment.file_name) {
        setFile(updateAttachment);
        setUpdateFileName(updateAttachment.file_name);
      }
    }
  }, [updateAttachment]);

  const uploadToS3 = (attachment) => {
    upLoadingStatus(true);
    setLoader(true);
    const fileExtension = attachment.name.split(".").pop().toLowerCase();
    const filename1 = attachment.name.replace(
      /(\.[\w\d_-]+)$/i,
      `${+Date.now().toString()}.${fileExtension}`
    );
    var photoBucket = new AWS.S3({
      params: {
        Bucket: "lms-email-attachement",
      },
    });
    photoBucket.upload(
      {
        ACL: "public-read",
        Body: attachment,
        Key: filename1, //attachment.name,
        ContentType: "application/octet-stream", // force download if it's accessed as a top location
      },
      (err, response) => {
        setLoader(false);
        upLoadingStatus(false);
        if (err) {
          handleSetAttachment(null, null, null);
          console.log("Something went wrong", err);
        } else {
          let documentSize = parseFloat(
            attachment.size / (1024 * 1024)
          ).toFixed(2);
          handleSetAttachment(attachment.name, response.Location, documentSize);
          setAttachLink(response.Location);
          console.log("AWS Response", response);
        }
      }
    );
  };
  const handleRomoveAttachment = () => {
    setFile("");
    setUpdateFileName(null);
    handleResetAttachment();
  };
  const handleChangeFileUpload = async (attachment) => {
    let { allow, type } = await checkFileExtension(attachment);
    if (allow) {
      const {
        data: { allowed, message },
      } = await checkDocumentFileName({ filename: attachment.name });
      if (allowed) {
        setFile(attachment);
        uploadToS3(attachment, type);
      } else {
        showAlert(message);
      }
    } else {
      showAlert("File type not allowed");
    }
  };
  return (
    <div className='App'>
      <FileUploader
        multiple={false}
        label='Upload or drop a file right here'
        handleChange={handleChangeFileUpload}
        name='file'
        types={fileTypes}
        fileOrFiles={file}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 5,
        }}
      >
        {updateFileName ? (
          <a
            href={updateAttachment.attachLink}
          >{`File name: ${updateFileName}`}</a>
        ) : (
          <a href={attachLink != "" ? attachLink : null}>
            {file && file != ""
              ? `File name: ${file?.name}`
              : "No file uploaded yet"}
          </a>
        )}
        {file && (file != "") & !loader ? (
          <button
            onClick={handleRomoveAttachment}
            style={{
              backgroundColor: "lightgray",
              borderRadius: 20,
              height: 20,
              width: 20,
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "black", fontStyle: "bold", fontSize: 15 }}>x</p>
          </button>
        ) : loader ? (
          <div className='loader-cont'>
            {" "}
            <p style={{ fontSize: 11, paddingRight: 3 }}>uploading file...</p>
            <Loader type='Oval' color='black' height={20} width={20} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
